<template>
  <div class="industry-capacity-list">
    <div class="content_block">
      <div class="audit-box">
        <div class="audit-title">审核状态</div>
        <el-radio-group v-model="query.auditStatus" @change="handleSearch" size="small">
          <el-radio-button v-for="item in AuditStatusList" :key="item.value" :label="item.value">
            <!-- {{ item.value === 'WAIT' ? `${item.name}(${count})` : item.name }} -->
            {{ item.name }}
          </el-radio-button>
        </el-radio-group>
      </div>
      <query-form labelWidth="110px" ref="queryForm" @search="handleSearch" @reset="handleReset">
        <el-form-item label="名称：">
          <el-input placeholder="请输入名称" v-model.trim="query.tenantName" clearable></el-input>
        </el-form-item>
        <el-form-item label="门户类型：">
          <el-select v-model="query.portalType" clearable>
            <el-option v-for="i in PortalTypeList" :key="i.value" :label="i.name" :value="i.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="提交开始时间：">
          <el-date-picker v-model="query.submitTimeStart" type="datetime" value-format="timestamp" placeholder="请选择提交开始时间" popper-class="xzpt" clearable></el-date-picker>
        </el-form-item>
        <el-form-item label="提交结束时间：">
          <el-date-picker v-model="query.submitTimeEnd" type="datetime" value-format="timestamp" placeholder="请选择提交结束时间" popper-class="xzpt" clearable></el-date-picker>
        </el-form-item>
      </query-form>
    </div>
    <table-with-pagination
      :tableData="tenantList"
      class="content_block"
      :columnList="columnList"
      :pagination.sync="pagination"
      @size-change="getTableList"
      @current-change="getTableList"
    />
  </div>
</template>
<script>
import { PortalTypeList } from '@/model/portalTypes';
import TableWithPagination, { TablePaginationMixin } from '@/components/common/TableWithPagination';
import { industryCapacityPageList } from '@/api/industryCapability';
import AuditStatusEnum, { AuditStatusList } from '@/model/industryCapability';
import { dateFormat } from '@/utils/dateFormat';
export default {
  name: 'INDUSTRY_CAPACITY_INDEX',
  mixins: [TablePaginationMixin],
  components: {
    TableWithPagination,
  },
  data () {
    return {
      // count: 0,
      query: {
        auditStatus: null,
        tenantName: '',
        portalType: '',
        submitTimeEnd: '',
        submitTimeStart: '',
        pageFlag: true,
      },
      PortalTypeList,
      AuditStatusList,
      tenantList: [],
      columnList: [
        {
          tooltip: true,
          label: '门户类型',
          minWidth: '100px',
          prop: 'portalTypeDesc',
        },
        {
          tooltip: true,
          label: '名称',
          minWidth: '150px',
          prop: 'tenantName',
        },
        {
          tooltip: true,
          label: '编号',
          minWidth: '150px',
          prop: 'tenantCode',
        },
        {
          tooltip: true,
          label: '提交人',
          minWidth: '150px',
          prop: 'submitUserName',
        },
        {
          tooltip: true,
          label: '提交人手机号',
          minWidth: '150px',
          prop: 'submitPhone',
        },
        {
          tooltip: true,
          label: '提交人邮箱',
          minWidth: '150px',
          prop: 'submitEmail',
        },
        {
          tooltip: true,
          label: '提交时间',
          minWidth: '150px',
          prop: 'submitTime',
          sortable: true,
          format: v => {
            return v ? dateFormat(v).format('YYYY-MM-DD HH:mm:ss') : '--';
          },
        },
        {
          tooltip: true,
          label: '审核状态',
          minWidth: '150px',
          prop: 'auditStatus',
          format: v => {
            return AuditStatusEnum[v];
          },
        },
        {
          label: '操作',
          minWidth: '200px',
          fixed: 'right',
          render: (h, { row }) => {
            let actions = [];

            if (row.auditStatus === 'WAIT') {
              actions.push(<span
                class="cursor-pointer text-primary margin-left"
                onClick={this.handleActionClick.bind(this, row, 'audit')}>
                审核
              </span>);
            }

            actions.push(<span
              class="cursor-pointer text-primary margin-left"
              onClick={this.handleActionClick.bind(this, row, 'check')}>
              查看
            </span>);
            return <div>{...actions}</div>;
          },
        },
      ],
    };
  },
  methods: {
    handleActionClick ({ id, tenantId }, action) {
      this.$router.push({
        name: 'INDUSTRY_CAPACITY_DETAIL',
        query: {
          id,
          tenantId,
          action,
        },
      });
    },
    handleReset () {
      this.query = {
        auditStatus: null,
        tenantName: '',
        portalType: '',
        submitTimeEnd: '',
        submitTimeStart: '',
        pageFlag: true,
      };
      this.handleSearch();
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    getTableList () {
      const query = {};

      for (let k in this.query) {
        if (this.query[k]) {
          query[k] = this.query[k];
        }
      }

      let param = {
        ...query,
        pageFlag: true,
        orderBy: [this.createTimeSortInfo],
        pageSize: this.pagination.pageSize,
        pageNum: this.pagination.currentPage,
      };

      industryCapacityPageList(param).then(res => {
        this.tenantList = res.body.list;
        this.pagination.total = +res.body.total || 0;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.industry-capacity-list {
  .audit-box {
    display: flex;
    align-items: center;
    padding: 15px;
    box-shadow: inset 0px -1px 0px 0px #DFE2E8;
    margin-bottom: 10px;
    .audit-title {
      margin-right: 10px;
      font-weight: 400;
      font-size: 14px;
      color: #606266;
    }
  }
}
</style>
